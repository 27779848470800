@import '~antd/dist/antd.less';
@import './vars.less';

// .App {
//   text-align: center;
// }

.spin-wrapper {
  height: 100vh;
  line-height: 100vh;
  text-align: center;
}

.focused {
  background: #fff;
  border-color: @orange;
  box-shadow: 0px 0px 2px 0px rgba(@orange, 0.59);
}

.common-search {
  position: relative;
  // margin-top: 12px;
  padding: 12px 0;
  // border-bottom: 1px solid #e5e6eb;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    content: ' ';
    display: block;
    width: 100%;
    height: 24px;
    background: #fff;
    border-bottom: 1px solid #f2f3f5;
  }

  .inner {
    position: relative;
    min-height: 100px;
    margin-right: 186px;
    // border-right: 1px solid #f2f3f5;
    font-size: 0;

    &::after {
      position: absolute;
      top: 12px;
      right: 0;
      content: ' ';
      display: block;
      width: 1px;
      height: calc(100% - 24px);
      background: #f2f3f5;
    }

    .ant-form-item {
      display: inline-block;
      // width: 326px;
      width: 366px;
      margin: 12px 0;
      vertical-align: top;

      .ant-form-item-label {
        > label {
          display: block;
          height: 32px;
          line-height: 32px;
          color: #666;

          &::after {
            margin-right: 4px;
          }
        }
      }

      .ant-select {
        .ant-select-selector {
          background: #f2f3f5;
          border-color: #f2f3f5;
          color: #4e5969;
        }

        &-focused .ant-select-selector {
          .focused();
        }
      }

      .ant-input-affix-wrapper {
        background: #f2f3f5;
        border-color: #f2f3f5;

        input {
          background: none;
          color: #4e5969;
        }

        &-focused {
          .focused();
        }
      }

      .ant-picker {
        width: 100%;
        background: #f2f3f5;
        border-color: #f2f3f5;

        &-focused {
          .focused();
        }
      }
    }
  }

  .actions {
    position: absolute;
    top: 24px;
    right: 0;
    display: flex;
    justify-content: space-between;
    width: 186px;
    padding-left: 22px;

    .buttons {
      .ant-btn + .ant-btn {
        background: #f2f3f5;
        margin-top: 12px;
        border-color: #f2f3f5;
      }
    }

    .trigger {
      .anticon {
        font-size: 10px;
      }
    }
  }

  &.common-search-collapsed {
    height: 80px;
    overflow: hidden;
  }

  &.common-search-simple {
    .inner {
      min-height: auto;
    }
  }
}

.common-summary {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;

  .total {
    height: 32px;
    line-height: 30px;
    background: rgba(@orange, 0.08);
    padding: 0 12px;
    border: 0.5px solid @orange;
    color: @font;
    font-size: 13px;

    b {
      margin: 0 4px;
      color: @orange;
      font-weight: normal;
    }
  }
}

.common-table {
  .ant-table {
    color: @font;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: none;
  }

  .ant-table.ant-table-small .ant-table-thead > tr > th {
    background: #faf8f7;
    border-bottom-color: #faf8f7;
    color: #31373d;
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    color: #31373d;
  }

  // .ant-table-tbody > tr > td {
  //   padding-top: 9px;
  //   padding-bottom: 8px;
  //   font-size: 13px;
  // }

  // .ant-table-tbody > tr.ant-table-row-selected > td {
  //   background: #f6fafe;
  // }

  // .ant-table-cell {
  //   .text-empty {
  //     color: #c2c6ce;
  //   }
  // }

  // .ant-table-empty {
  //   .ant-table-placeholder {
  //     padding: 0;
  //   }
  // }

  .ant-btn {
    padding: 0;
  }

  .common-status {
    display: flex;
    align-items: center;
  }

  .tooltip-text {
    width: 140px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.common-dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-right: 6px;
  border-radius: 50%;
  vertical-align: top;

  &.common-dot-default {
    background: @gray;
    box-shadow: 0 0 0 2px rgba(@gray, 0.5);
  }

  &.common-dot-error {
    background: @red;
    box-shadow: 0 0 0 2px rgba(@red, 0.5);
  }

  &.common-dot-success {
    background: @green;
    box-shadow: 0 0 0 2px rgba(@green, 0.5);
  }

  &.common-dot-processing {
    background: @blue;
    box-shadow: 0 0 0 2px rgba(@blue, 0.5);
  }

  &.common-dot-warning {
    background: @yellow;
    box-shadow: 0 0 0 2px rgba(@yellow, 0.5);
  }
}

@primary-color: #ff7e3b;@text-color: #4f4f4f;