@import '../../styles/vars.less';

.Login {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(241deg, #ea641e 0%, #f8742f 100%);

  .header {
    display: flex;
    justify-content: space-between;
    padding: 60px 70px 96px;
    color: #fff;

    h1 {
      margin: 0;
      color: #fff;
      font-size: 30px;
      letter-spacing: 0.03px;
    }

    span {
      margin-top: 12px;
      height: 22px;
      line-height: 22px;
      font-size: 16px;

      b {
        display: inline-block;
        width: 22px;
        height: 22px;
        background: #ffffff;
        margin-right: 10px;
        color: #f8742f;
        border-radius: 2px;
        text-align: center;
        vertical-align: top;
      }
    }
  }

  .outter {
    width: 100%;
    min-height: 620px;
    background: url('../../assets/images/login_bg.png') no-repeat 12% 0 / 731px
      520px;

    .form-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 1000px;
      margin: 0 auto;

      .pic {
        width: 487px;
        height: 323px;
        background: url('../../assets/images/login_pic.png') no-repeat 0 0 /
          contain;
        margin-top: 50px;
      }
    }

    .form {
      position: relative;
      width: 397px;
      background: #fff;
      padding: 0 25px 75px;
      border-radius: 4px;

      .title {
        position: relative;
        z-index: 99;
        padding: 36px 0 20px;
        border-bottom: 0.5px solid #c5cad5;
        color: #111a34;
        font-size: 16px;
        text-indent: 10px;
      }

      .change {
        position: absolute;
        top: 0;
        right: 0;
        width: 56px;
        height: 56px;
        background: #f5f5f5;
        border-radius: 0 4px 0 0;
        text-align: right;
        cursor: pointer;

        &::before {
          content: ' ';
          display: block;
          width: 80px;
          height: 56px;
          background: #fff;
          position: absolute;
          top: 0;
          left: 0;
          transform: rotate(45deg);
          transform-origin: 0 0;
        }

        i {
          display: inline-block;
          width: 24px;
          height: 24px;
          margin: 10px 10px 0 0;
          vertical-align: top;

          &.icon-account {
            background: url('../../assets/images/login_icon_account.png')
              no-repeat center center;
          }
          &.icon-mobile {
            background: url('../../assets/images/login_icon_mobile.png')
              no-repeat center center;
          }
        }
      }

      .inner {
        // position: absolute;
        // top: 50%;
        // left: 190px;
        // transform: translateY(-50%);
        // margin: 0 0 0 190px;
        // padding-top: 140px;
        padding: 0 9px;

        .ant-form-item {
          position: relative;
        }

        .ant-form-item-label {
          position: absolute;
          top: 16px;
          left: 24px;
          z-index: 11;

          label {
            color: #858b9c;
          }
        }

        .ant-input-affix-wrapper {
          padding: 0;
          border: none;

          &:focus,
          &-focused {
            box-shadow: none;
          }

          .ant-input-suffix {
            position: absolute;
            right: 12px;
            bottom: 17px;

            svg {
              color: #c6cad4;
            }
          }
        }

        .ant-input {
          height: 48px;
          line-height: 48px;
          background: #f7f8fb;
          padding: 0 24px;
          border: none;
          border-radius: 6px;
          color: #858b9c;
          box-shadow: 0 0 0 1000px #f7f8fb inset;

          ::placeholder {
            color: #858b9c;
          }
        }

        .username {
          margin-top: 36px;

          .ant-input {
            height: 48px;
            line-height: 48px;
            background: #f7f8fb;
            padding: 0 24px;
            border: none;
            border-radius: 6px;
            box-shadow: 0 0 0 1000px #f7f8fb inset;

            ::placeholder {
              color: #858b9c;
            }
          }
        }

        .captcha {
          position: relative;
          margin-bottom: 12px;

          .ant-form-item {
            margin: 0;
          }

          img {
            position: absolute;
            right: 12px;
            bottom: 12px;
            z-index: 11;
            height: 24px;
            cursor: pointer;
          }
        }

        .forget-pw {
          margin-bottom: 32px;

          .ant-btn-link {
            color: #858b9c;
            font-size: 12px;
          }
        }

        .mobile {
          margin-top: 36px;
        }

        .ant-statistic {
          position: absolute;
          right: 12px;
          bottom: 12px;

          .ant-statistic-content {
            color: #858b9c;
            font-size: 14px;
          }
        }

        .code {
          position: relative;
          margin-bottom: 36px;

          .ant-form-item {
            margin: 0;
          }

          .ant-btn {
            position: absolute;
            right: 12px;
            bottom: 12px;
            z-index: 11;
            padding: 0;
          }
        }

        .submit {
          margin: 0;

          .ant-btn {
            display: block;
            width: 100%;
            height: 44px;
            background: #f77d3e;
            color: #fff;
            font-size: 16px;
            border-radius: 22px;
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
    color: rgba(#fff, 0.6);
    font-size: 12px;
    text-align: center;

    a {
      color: rgba(#fff, 0.6);
    }
  }
}

.Reset {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -70%);

  .form {
    width: 360px;
    margin-top: 36px;

    .code {
      position: relative;

      .ant-form-item {
        margin: 0;
      }

      .ant-btn {
        position: absolute;
        right: 12px;
        bottom: 4px;
        z-index: 11;
        padding: 0;
      }

      .ant-statistic {
        position: absolute;
        right: 12px;
        bottom: 4px;

        .ant-statistic-content {
          color: #858b9c;
          font-size: 14px;
        }
      }
    }
  }
}

@primary-color: #ff7e3b;@text-color: #4f4f4f;